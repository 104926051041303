import React, { useState, useEffect } from 'react'
import Selectpicker from '../../shared/inputs/selectpicker'
import SwitchInput from '../../shared/inputs/SwitchInput'

const ConfigClaimBanner = (props) => {
  const [state, setState] = useState({...props.banner_info, error: false});
  const [disableShareAlert, setDisableShareAlert] = useState(String(props.configurationType) != '1');

  useEffect(() => {
    subscribeEvents();

    return () => PubSub.unsubscribe('ClaimConfigurationTypeChanged')
  }, [])

  const subscribeEvents = () => {
    PubSub.subscribe('ClaimConfigurationTypeChanged', (topic, configurationType) => {
      setDisableShareAlert(String(configurationType) != '1')
    })
  }

  const handleNameChange = (fieldName, e) => {
    let newValue = e.target.value
    setState(prevState => ({
      ...prevState,
      [fieldName]: newValue
    }))
    $(`.tpa_name`)[0].setCustomValidity("");
    $(`.tpa_link`)[0].setCustomValidity("");
    $(`.claim_portal_text`)[0].setCustomValidity("");
  }

  const saveBannerInfo = () => {
    let errorField;
    if (state.tpa_name == '' || state.tpa_link == '' || state.claim_portal_text == '') {
      setState(prevState => ({
        ...prevState,
        error: true
      }));
      if (state.tpa_name == '') {
        errorField = 'tpa_name'
      } else if (state.tpa_link == '') {
        errorField = 'tpa_link'
      } else {
        errorField = 'claim_portal_text'
      }
      $(`.${errorField}`)[0].setCustomValidity("Please fill out this field.");
      $(`.${errorField}`)[0].reportValidity();
      return;
    }
    let companyUrl = props.company_level ? 'companies/' :''
    let paramName = props.company_level ? 'company_claim_configuration' : 'claim_configuration'
    let url = `/admin/${companyUrl}claim_configurations/${state.id}/update_banner_info`
    $.ajax({
      method: 'PUT',
      url: url,
      data: {[paramName] : state},
      success: (response) => {
        if (response.success){
          toastr.success(response.message)
        } else {
          toastr.error(response.message)
        }
      },
      error: (response) => {
        toastr.error(response.responseJSON.message)
      }
    })
  }

  const renderEnableBanner = () => {
    return (
      <div className="form-group" style={{
        paddingTop: 12
      }}>
        <div className="row">
          <div className="col-sm-5">
            <div className="col-md-9 switch-label">
              <label >Enable Claims Education Banner</label>
            </div>
            <div className="col-md-1">
              <SwitchInput
                name="enable_banner"
                checked={state.enabled_education_banner}
                id="enabled_education_banner"
                onChange={(checked) => handleChange('enabled_education_banner',checked)}
              />
            </div>
          </div>
          <div className="col-sm-2">
          </div>
          <div className="col-sm-5">
            <div className="col-md-9 switch-label">
            </div>
            <div className="col-md-1">
              <button
                className="btn btn-primary btn-sm"
                onClick={saveBannerInfo}
              >Save</button>
            </div>
          </div>
        </div>
      </div>
    )
  }


  const renderTextFields = () => {
    return (
      <>
        <div className="form-group">
          <div className="row">
            <div className="col-sm-5">
              <div className="col-md-9">
                <label>TPA Display Name</label>
                <input
                  type="text"
                  className="form-control tpa_name"
                  name="tpa_name"
                  value={ state.tpa_name }
                  onChange={ (e) => handleNameChange('tpa_name',e) }
                  style={{
                    width: 500
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-sm-5">
              <div className="col-md-9">
                <label>TPA Claims Portal Link</label>
                <input
                  type="text"
                  className="form-control tpa_link"
                  name="tpa_link"
                  value={ state.tpa_link }
                  onChange={ (e) => handleNameChange('tpa_link', e) }
                  style={{
                    width: 500
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-sm-5">
              <div className="col-md-9">
              <label>TPA Claims Portal Text to Display</label>
              <input
                type="text"
                className="form-control claim_portal_text"
                name="claim_portal_text"
                value={ state.claim_portal_text }
                onChange={ (e) => handleNameChange('claim_portal_text', e) }
                style={{
                  width: 500
                }}
              />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const handleChange = (fieldName, checked) => {
    setState(prevState => ({
      ...prevState,
      [fieldName]: checked
    }))
  }

  const bannerToggles = () => {
    return (
      <>
      <div className="form-group">
        <div className="row">
          <div className="col-sm-5">
            <div className="col-md-9 switch-label">
              <label >RLSD > 30 days in Future</label>
            </div>
            <div className="col-md-1">
              <SwitchInput
                name="rlsd_gt_30_future"
                checked={state.rlsd_gt_30_future}
                id="rlsd_gt_30_future"
                onChange={(checked) => handleChange('rlsd_gt_30_future',checked)}
              />
            </div>
          </div>
          <div className="col-sm-2">
          </div>
          <div className="col-sm-5">
            <div className="col-md-9 switch-label">
              <label >Post Share Action Required</label>
            </div>
            <div className="col-md-1">
              <SwitchInput
                name="post_share_required"
                checked={state.post_share_required}
                disabled={disableShareAlert}
                id="post_share_required"
                onChange={(checked) => handleChange('post_share_required',checked)}
              />
            </div>
          </div>
        </div>
      </div>
        <div className="form-group">
          <div className="row">
            <div className="col-sm-5">
              <div className="col-md-9 switch-label">
                <label >RLSD = 30 days in Future</label>
              </div>
              <div className="col-md-1">
                <SwitchInput
                  name="rlsd_lt_30_future"
                  checked={state.rlsd_lt_30_future}
                  id="rlsd_lt_30_future"
                  onChange={(checked) => handleChange('rlsd_lt_30_future',checked)}
                />
              </div>
            </div>
            <div className="col-sm-2">
            </div>
            <div className="col-sm-5">
              <div className="col-md-9 switch-label">
                <label >Edit Alert</label>
              </div>
              <div className="col-md-1">
                <SwitchInput
                  name="enabled_edit_alert"
                  checked={state.enabled_edit_alert}
                  id="enabled_edit_alert"
                  onChange={(checked) => handleChange('enabled_edit_alert',checked)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-sm-5">
              <div className="col-md-9 switch-label">
                <label >RLSD in the Past</label>
              </div>
              <div className="col-md-1">
                <SwitchInput
                  name="rlsd_past"
                  checked={state.rlsd_past}
                  id="rlsd_past"
                  onChange={(checked) => handleChange('rlsd_past',checked)}
                />
              </div>
            </div>
            <div className="col-sm-2">
            </div>
            <div className="col-sm-5">
              <div className="col-md-9 switch-label">
                <label >Post Edit Action Required</label>
              </div>
              <div className="col-md-1">
                <SwitchInput
                  name="post_edit_action_required"
                  checked={state.post_edit_action_required}
                  id="post_edit_action_required"
                  onChange={(checked) => handleChange('post_edit_action_required',checked)}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      {renderEnableBanner()}
      {renderTextFields()}
      {bannerToggles()}
    </>
  )
}

export default ConfigClaimBanner;
